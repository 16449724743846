<template>
    <div class="container-fluid h-100 my-2">
        <div class="row justify-content-center align-items-center h-100">
            <div class="col-auto">
                <div class="box" id="login">
                    <groomy-logo class="logo_center_bis" />
                    <p class="text-center message_chargement mb-0 mt-4">
                        <font-awesome-icon class="spinner_home" :icon="['fas', 'spinner']" pulse /><br/>{{ progressBarText }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/mixins/User'
import Navigation from '@/mixins/Navigation.js'

export default {
    name: 'LoadingScreen',
    mixins: [User, Navigation],
    props: {
        fetchRequired: {
            type: Boolean,
            default: () => (false)
        }
    },
    data: () => ({
        typeProgress: 'IAL'
    }),
    computed: {
        progressBarText: function() {
            if(this.typeProgress) {
                return this.getTrad('progression_message.'+this.typeProgress)
            }
            return ''
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            // Se produit quand une URL loggedIn est demandée alors que le user n'est pas connecté
            if (!this.checkLocal()) {
                return
			}

            this.typeProgress = 'IAL'
            await this.initLocalConfig()

            this.typeProgress = 'IAL'
			await this.initRemoteConfig()

            await this.initUserPermissions()
            this.$store.commit('common/ackLoadConfig')
            this.redirect()
        },
        async initLocalConfig() {
			// Setup licence, bearer et langue en local storage
			await this.initSession()
		},
		async initRemoteConfig() {
			// Setup config localstorage global
			await this.persistUserConfig()
			await this.pesistUserCaConfig()
		},       
        redirect() {
            if (this.$route.query.redirect && this.$route.query.redirect != '/') {
                this.$router.replace(this.$route.query.redirect)
            } else {
                const default_redirect = this.defaultCaRedirect()

                if(default_redirect.indexOf('/') > -1){
                    this.$router.push({ path: default_redirect })
                }
                else{
                    this.$router.push({ name: default_redirect })
                }
            }
        }
    }
}
</script>
